// src/features/quiz/components/QuizDashboard.tsx
import React from 'react'
import { Box, Button, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const QuizDashboard: React.FC = () => {
  const startQuiz = () => {
    // Handle quiz start logic
  }

  return (
    <Box p='4'>
      <Text fontSize='xl' mb='4'>
        Not found !
      </Text>
      <Button colorScheme='green' onClick={startQuiz}>
        Start Quiz
      </Button>
      <Box mt='4'>
        <Link to='/'>Back to Home</Link>
      </Box>
    </Box>
  )
}

export default QuizDashboard
