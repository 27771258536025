// components/about/AboutUs.tsx
import React from 'react'
import { Container, Box, Heading, Text, Divider, Image, Tooltip } from '@chakra-ui/react'

const AboutUs = () => {
  return (
    <Container maxW='100%' mt={5}>
      <Box>
        <Heading as='h3' fontSize={'28px'} fontWeight={'600'} mb={4}>
          Our Mission
        </Heading>
        <Text color='gray.600'>
          ProCertQuiz is a website that provides practice tests and reviews for various professional
          certificates in fields such as information technology, project management, accounting, and
          auditing. Currently, the website only focuses on software testing topics (ISTQB) in its
          first stage and will improve with each release.
        </Text>
      </Box>

      <Divider my={8} />

      <Box>
        <Heading as='h3' fontSize={'28px'} fontWeight={'600'} mb={4}>
          Development Timeline
        </Heading>
        <Text color='gray.600'>
          Due to limited resources, each release will only include minor feature updates. However,
          in the future, as we expand our team, we will continue to develop more features to better
          serve the community.
        </Text>
        <Box mt={6}>
          {/* Giả sử bạn có một roadmap như sau */}
          <Heading as='h4' fontSize={'20px'} fontWeight={'500'} mb={3}>
            Roadmap
          </Heading>
          <Box ml={'30px'}>
            <ul>
              <li>Phase 1: Planning and Research (09/2023 - 10/2023)</li>
              <li>Phase 2: Design and Prototyping (12/2023 - 01/2023)</li>
              <li>Phase 3: Development and Testing (02/2024 - 04/2024)</li>
              <li>Phase 4: Deployment and Optimization (04/2024)</li>
              <li>Phase 5: New plan and update more features (04/2024 and Later)</li>
            </ul>
          </Box>
        </Box>
      </Box>

      <Divider my={8} />

      <Box>
        <Heading as='h3' fontSize={'28px'} fontWeight={'600'} mb={4}>
          Our Team
        </Heading>
        <Text color='gray.600'>
          I (Binh Tran) had a personal need to obtain ISTQB certification, but I found it
          challenging to locate reliable learning resources online. Many of the available resources
          were outdated, and it was difficult to find quality learning materials. As a result, I
          developed a strong desire to create and maintain a website that could provide up-to-date
          and reliable ISTQB certification resources for learners like myself.
        </Text>
        <Box mt={6} display='flex' alignItems='center'>
          <Tooltip
            placement='right'
            label='I am a software tester with a passion for
            coding. I constantly strive to expand my knowledge and skills by building websites that
            help students and professionals seeking international certifications. My goal is to
            provide them with the necessary knowledge and tools to advance their careers.'
          >
            <Image
              src='https://hi.thanhbinhbent.com/assets/images/my-avatar.jpeg' // Đặt URL hình ảnh của tác giả
              alt='Author Avatar'
              borderRadius='full'
              boxSize='50px'
              mr={4}
            />
          </Tooltip>
          <Box>
            <Heading as='h4' fontSize={'20px'} fontWeight={'500'} mb={2}>
              Binh Tran
            </Heading>
            <Text color='gray.600'>Software Tester</Text>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default AboutUs
