// src/App.tsx
import React from 'react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Layout from './components/common/Layout'
import Login from './components/auth/Login'
import Register from './components/auth/Register'
import QuizDashboard from './components/quiz/QuizDashboard'
import HomePage from './pages/Home'
import './App.css'
import ReleaseNotes from './pages/ReleaseNotes'
import AboutUs from './pages/AboutUs'
import SubjectDetail from './pages/SubjectDetail'
import NotFound from './pages/NotFound'
import ReviewExam from './pages/ReviewExam'

const colors = {
  brand: {
    900: '#09326C',
    800: '#0055CC',
    700: '#0C66E4',
    600: '#1D7AFC',
    500: '#388BFF',
    400: '#579DFF',
    300: '#85B8FF',
    200: '#CCE0FF',
    100: '#E9F2FF',
  },
  text: {
    900: '#172B4D',
    800: '#172B4D',
    700: '#172B4D',
    600: 'white',
    500: '#E2E8F0',
  },
}
const theme = extendTheme({ colors })

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes>
          <Route
            path='/'
            element={
              <Layout>
                <HomePage />
              </Layout>
            }
          />
          <Route
            path='/register'
            element={
              <Layout>
                <Register />
              </Layout>
            }
          />
          <Route
            path='/roadmap'
            element={
              <Layout>
                <ReleaseNotes />
              </Layout>
            }
          />
          <Route
            path='/about-us'
            element={
              <Layout>
                <AboutUs />
              </Layout>
            }
          />
          <Route
            path='/quiz/:subjectId'
            element={
              <Layout>
                <SubjectDetail />
              </Layout>
            }
          />
          <Route
            path='/login'
            element={
              <Layout>
                <Login />
              </Layout>
            }
          />
          <Route
            path='/quiz-dashboard'
            element={
              <Layout>
                <QuizDashboard />
              </Layout>
            }
          />
          <Route
            path='/quiz/review/:examId'
            element={
              <Layout>
                <ReviewExam />
              </Layout>
            }
          />
          <Route
            path='*'
            element={
              <Layout>
                <NotFound />
              </Layout>
            }
          />
        </Routes>
      </Router>
    </ChakraProvider>
  )
}

export default App
