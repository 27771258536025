// components/common/Layout.tsx
import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Flex, Spacer } from '@chakra-ui/react'
import Header from './Header'
import Footer from './Footer'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Box>
      <Flex>
        <Header />
        <Spacer />
      </Flex>
      <Box p='4'>{children}</Box>
      <Footer />
    </Box>
  )
}

export default Layout
