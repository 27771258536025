// components/common/Header.tsx
import React from 'react'
import { Box, Flex, Heading, Text, Button, Badge, Tooltip } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
const Header: React.FC = () => {
  return (
    <Box p='4' width={'100%'} borderWidth={1} borderBottomColor={'text.500'}>
      <Flex>
        <Box display={'flex'}>
          <Box textAlign={'center'}>
            <Link to='/'>
              <Heading as='h1' fontSize={'24px'} color={'brand.700'}>
                ProCertQuiz
              </Heading>
              <Text fontSize='8.5px'>Professional Certificate Quizzes</Text>
            </Link>
          </Box>
          <Text
            ml='8'
            display={'flex'}
            alignItems='center'
            _hover={{ cursor: 'pointer', color: 'brand.700' }}
          >
            <Link to={'/about-us'}>About us</Link>
          </Text>
          <Tooltip label='Coming soon!' aria-label='A tooltip'>
            <Text ml='8' display={'flex'} alignItems='center' _hover={{ cursor: 'not-allowed' }}>
              API
            </Text>
          </Tooltip>
          <Text
            ml='8'
            display={'flex'}
            alignItems='center'
            _hover={{ cursor: 'pointer', color: 'brand.700' }}
          >
            {' '}
            <Link to='/roadmap'>Roadmap </Link>
            <Badge ml='1' colorScheme='green'>
              New
            </Badge>
          </Text>
        </Box>
        <Box ml='auto' display={'flex'}>
          {/* <Link to='/register'> */}
          <Tooltip label='Coming soon!' aria-label='A tooltip'>
            <Button
              disabled
              colorScheme='gray'
              variant='outline'
              color={'text.700'}
              fontWeight={400}
            >
              Register
            </Button>
          </Tooltip>
          {/* </Link> */}
          {/* <Link to='/login'> */}
          <Tooltip label='Coming soon!' aria-label='A tooltip'>
            <Button ml={3} colorScheme='brand' variant='outline'>
              Login
            </Button>
          </Tooltip>
          {/* </Link> */}
        </Box>
      </Flex>
    </Box>
  )
}

export default Header
