import React, { useState } from 'react'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react'

const Register: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  return (
    <Container
      maxW='lg'
      py={{ base: '12', md: '12' }}
      px={{ base: '0', sm: '8' }}
      transition='all 0.9s'
    >
      <Stack spacing='8'>
        <Stack spacing='6'>
          <Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
            <Heading as='h1' size='lg' color={'brand.700'}>
              ProCertQuiz
            </Heading>
            <Heading fontWeight={500} color='text.700' size={{ base: 'xs', md: 'sm' }}>
              Create your account
            </Heading>
          </Stack>
        </Stack>
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={{ base: 'transparent', sm: 'bg.surface' }}
          borderWidth={1}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Stack spacing='6'>
            <Stack spacing='5'>
              <FormControl isRequired>
                <FormLabel htmlFor='email'>Email</FormLabel>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  focusBorderColor='brand.500'
                  id='email'
                  type='email'
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor='password'>Password</FormLabel>
                <Input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  focusBorderColor='brand.500'
                  id='password'
                  type='password'
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel htmlFor='confirmPassword'>Confirm Password</FormLabel>
                <Input
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  focusBorderColor='brand.500'
                  id='confirmPassword'
                  type='password'
                />
              </FormControl>
            </Stack>
            <Stack spacing='6'>
              <Button colorScheme='brand'>Register</Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

export default Register
