import { Button, ButtonGroup, Text, Tooltip } from '@chakra-ui/react'
import { GoogleIcon } from '../common/ProviderIcons'

const providers = [{ name: 'Google', icon: <GoogleIcon /> }]

export const OAuthButtonGroup = () => (
  <ButtonGroup variant='outline' spacing='4'>
    <Tooltip label='Coming soon!' aria-label='A tooltip'>
      <Button flexGrow={1} isDisabled>
        <GoogleIcon />
        <Text ml='3'> Sign in with Google</Text>
      </Button>
    </Tooltip>
  </ButtonGroup>
)
