import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Text,
  VStack,
  Heading,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Skeleton,
} from '@chakra-ui/react'
import { Exam } from '../interface/exam.interface'
import { getTestExams } from '../services/examService'
import ExamList from '../components/exam/ExamList'
import NotFound from './NotFound'
import { Subject } from '../interface/subject.interface'
import subjectService from '../services/subjectService'

const SubjectDetail: React.FC = () => {
  const { subjectId } = useParams<{ subjectId: string }>()
  const [exams, setExams] = useState<Exam[]>([])
  const [subject, setSubject] = useState<Subject | null>(null)
  const [loading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)

  useEffect(() => {
    const fetchExams = async () => {
      try {
        const fetchedExams = await getTestExams(subjectId)
        setExams(fetchedExams)
        setLoading(false)
        if (!fetchedExams || fetchedExams.length === 0) {
          setNotFound(true)
        }
      } catch (error) {
        console.error('Error fetching exams:', error)
        setLoading(false)
        setNotFound(true)
      }
    }
    const fetchSubject = async () => {
      try {
        const subjectData = subjectId && (await subjectService.getSubjectById(subjectId))
        subjectData && setSubject(subjectData)
      } catch (error) {
        console.error('Error fetching subject:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchSubject()
    fetchExams()
  }, [subjectId])

  // Move the check for notFound outside of useEffect
  if (notFound) {
    return <NotFound />
  }
  return (
    <Box>
      <Breadcrumb fontWeight='medium' fontSize='sm' colorScheme='gray'>
        <BreadcrumbItem>
          <BreadcrumbLink href='/'>Home</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href='/'>Quiz</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href='#'> {subject?.subject}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Heading
        mt={'20px'}
        as='h3'
        fontSize={'28px'}
        fontWeight={'600'}
        size='xl'
        mb={2}
        color='text.900'
      >
        {subject?.subject}
      </Heading>
      <Text marginBottom='8'>{subject?.description}</Text>
      <Text fontSize='2xl' fontWeight='bold' mb='4'></Text>
      <VStack spacing='4' align='start'>
        {loading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <Box
              borderWidth='1px'
              borderRadius='md'
              padding={2}
              key={index}
              p='2'
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'100%'}
            >
              <Flex width='50%' flexDirection={'row'}>
                <Skeleton height='30px' width='20px' />
                <Skeleton ml={5} height='30px' flex={1} />
              </Flex>
              <Box width={'80px'} ml={5}>
                <Skeleton height='30px' width='100%' my={2} />
              </Box>
            </Box>
          ))
        ) : (
          <ExamList exams={exams} />
        )}
      </VStack>
    </Box>
  )
}

export default SubjectDetail
