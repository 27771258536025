// components/common/Footer.tsx
import React from 'react'
import { Box, Text } from '@chakra-ui/react'

const Footer: React.FC = () => {
  const year = new Date().getFullYear()
  return (
    <Box p='4' mt='auto' borderTopWidth={1} borderTopColor={'text.500'}>
      <Text textAlign='center' fontSize='sm' color='gray.600'>
        © {year} ProCertQuiz. All rights reserved.
      </Text>
    </Box>
  )
}

export default Footer
