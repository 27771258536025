// components/auth/Login.tsx
import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Divider,
  HStack,
  Input,
  Tooltip,
  Stack,
  Text,
} from '@chakra-ui/react'
import { OAuthButtonGroup } from './OAuthButtonGroup'
import { PasswordField } from './PasswordField'

const Login: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)

  const handleLogin = () => {
    // Validate input if needed
    // Call the onLogin function passed as a prop
  }

  return (
    <Container
      maxW='lg'
      py={{ base: '12', md: '12' }}
      px={{ base: '0', sm: '8' }}
      transition='all 0.9s'
    >
      <Stack spacing='8'>
        <Stack spacing='6'>
          <Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
            <Heading as='h1' size='lg' color={'brand.700'}>
              ProCertQuiz
            </Heading>
            <Heading fontWeight={500} color='text.700' size={{ base: 'xs', md: 'sm' }}>
              Log in to your account
            </Heading>
          </Stack>
        </Stack>
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={{ base: 'transparent', sm: 'bg.surface' }}
          borderWidth={1}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Stack spacing='6'>
            <Stack spacing='5'>
              <FormControl>
                <FormLabel htmlFor='email'>Email:</FormLabel>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  focusBorderColor='brand.500'
                  id='email'
                  type='text'
                />
              </FormControl>
              <PasswordField />
            </Stack>
            <HStack justify='space-between'>
              <Checkbox
                defaultChecked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                colorScheme='brand'
              >
                Remember me
              </Checkbox>
              <Tooltip label='Coming soon!' aria-label='A tooltip'>
                <Button variant='text' size='sm' isDisabled>
                  Forgot password?
                </Button>
              </Tooltip>
            </HStack>
            <Stack spacing='6'>
              <Button colorScheme='brand' onClick={handleLogin}>
                Sign in
              </Button>
              <HStack>
                <Divider />
                <Text textStyle='sm' whiteSpace='nowrap' color='fg.muted'>
                  or continue with
                </Text>
                <Divider />
              </HStack>
              <OAuthButtonGroup />
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

export default Login
