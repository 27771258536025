import React, { useState } from 'react'
import parse from 'html-react-parser'
import { Container, Box, Heading, Text, List, ListItem, Collapse, Badge } from '@chakra-ui/react'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'

const ReleaseNotes = () => {
  const releases = [
    {
      version: '1.2.0',
      description:
        '<p>- Users can manage profile <br/>- Update ISTQB V4.0 <br/>- Update more Certification Exams</p>',
      date: '05/01/2024',
      current: false,
    },
    {
      version: '1.1.0',
      description:
        '<p>- Users can create an account and login <br/>- Can take an exam with countdown</p>',
      date: '03/01/2024',
      current: false,
    },
    {
      version: '1.0.0-beta',
      description:
        '<p>- Only support ISTQB V3.1 exam <br/>- Only allows users to review tests, does not support time counting <br/>- Show/Hide answers</p>',
      date: '01/01/2024',
      current: true,
    },
    // Add more releases as needed
  ]

  const [openRelease, setOpenRelease] = useState(releases[0].version)

  const toggleRelease = (version: string) => {
    setOpenRelease((prevOpenRelease) => (prevOpenRelease === version ? null : version) as string)
  }

  return (
    <Container maxW='100%' mt={5}>
      <Heading
        as='h3'
        fontSize={'28px'}
        fontWeight={'600'}
        size='xl'
        mb={2}
        color='text.900'
        marginBottom='8'
      >
        Our Roadmap
      </Heading>

      <List spacing={3}>
        {releases.map((release, index) => (
          <ListItem
            key={index}
            borderBottom={index === releases.length - 1 ? 'none' : '1px solid'}
            borderColor='gray.200'
            pb={4}
          >
            <Box>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Heading
                  fontWeight={'500'}
                  color={'text.400'}
                  display={'flex'}
                  as='p'
                  size='md'
                  onClick={() => toggleRelease(release.version)}
                  cursor='pointer'
                  _hover={{ color: 'brand.700' }}
                  alignItems={'center'}
                >
                  {openRelease === release.version ? (
                    <Box fontSize={'14px'}>
                      <FaChevronDown />
                    </Box>
                  ) : (
                    <Box fontSize={'14px'}>
                      <FaChevronRight />
                    </Box>
                  )}
                  &nbsp; Version {release.version}{' '}
                  {release.current ? (
                    <Badge variant='subtle' colorScheme='green' ml={2}>
                      Current
                    </Badge>
                  ) : (
                    <Badge variant='subtle' colorScheme='red' ml={2}>
                      Upcoming
                    </Badge>
                  )}
                </Heading>
                <Text color='gray.500' fontSize='sm'>
                  {release.date}
                </Text>
              </Box>
              <Collapse in={openRelease === release.version}>
                <Box mt={2} border='1px solid' borderColor='gray.200' p={3} borderRadius='md'>
                  <Text color='gray.600'>{parse(release.description)}</Text>
                </Box>
              </Collapse>
            </Box>
          </ListItem>
        ))}
      </List>
    </Container>
  )
}

export default ReleaseNotes
