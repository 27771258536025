import axios, { AxiosError } from 'axios'
import { Exam } from '../interface/exam.interface'

const API_BASE_URL =
  process.env.NODE_ENV == 'production'
    ? process.env.REACT_APP_BASE_URL_PROD
    : process.env.REACT_APP_BASE_URL_DEV

const getTestExams = async (subjectId?: string): Promise<Exam[]> => {
  try {
    const response = await axios.get<Exam[]>(`${API_BASE_URL}/test-exams`, {
      params: { subjectId },
    })
    return response.data
  } catch (error) {
    const axiosError = error as AxiosError
    throw new Error(`Error fetching exams: ${axiosError.message}`)
  }
}

const getTestExamById = async (testExamId: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/test-exams/${testExamId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching test exam:', error)
    throw error
  }
}

export { getTestExams, getTestExamById, API_BASE_URL }
