import axios, { AxiosInstance } from 'axios'
import { Question } from '../interface/question.interface'

const apiService: AxiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV == 'production'
      ? process.env.REACT_APP_BASE_URL_PROD
      : process.env.REACT_APP_BASE_URL_DEV,
})

const questionService = {
  getQuestionsById: async (examId: string): Promise<Question[]> => {
    try {
      const response = await apiService.get('/questions', {
        params: { examId },
      })
      return response.data
    } catch (error) {
      console.error('Error fetching questions:', error)
      throw error
    }
  },
}

export default questionService
