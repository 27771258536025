import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { Subject } from '../interface/subject.interface'

const apiService: AxiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV == 'production'
      ? process.env.REACT_APP_BASE_URL_PROD
      : process.env.REACT_APP_BASE_URL_DEV,
})

const handleResponse = <T>(response: AxiosResponse<T>): T => {
  if (response.status >= 200 && response.status < 300) {
    return response.data
  } else {
    throw new Error(`Request failed with status ${response.status}`)
  }
}

const handleError = (error: any): never => {
  console.error('Request error:', error.message || error)
  throw error
}

const subjectService = {
  getSubjects: async (): Promise<Subject[]> => {
    try {
      const response = await apiService.get<Subject[]>('/subjects')
      return handleResponse(response)
    } catch (error) {
      return handleError(error)
    }
  },

  getSubjectById: async (subjectId: string): Promise<Subject> => {
    try {
      const response = await apiService.get<Subject>(`/subjects/${subjectId}`)
      return handleResponse(response)
    } catch (error) {
      return handleError(error)
    }
  },
}

export default subjectService
