import React from 'react'
import { Box, Heading, Text, VStack, Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <VStack spacing={4} align='center' justify='center' paddingTop={'50px'} paddingBottom={'100px'}>
      <Box textAlign='center'>
        <Heading fontSize='6xl' color='brand.700' mb={4}>
          404
        </Heading>
        <Text fontSize='2xl' fontWeight='bold' mb={4}>
          Oops! Page not found.
        </Text>
        <Text fontSize='lg' color='gray.500' mb={8}>
          The page you are looking for might be under construction.
        </Text>
        <Button colorScheme='brand' variant='outline' as={Link} to='/'>
          Go back home
        </Button>
      </Box>
    </VStack>
  )
}

export default NotFound
