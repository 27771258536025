import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom' // Import useNavigate instead of useHistory
import {
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import ReviewIcon from '../../images/icon/review-mode.svg' // Replace with the actual path to your Review mode icon
import TestIcon from '../../images/icon/exam-mode.svg' // Replace with the actual path to your Test mode icon;

interface QuizModeSelectorProps {
  isOpen: boolean
  onClose: () => void
  examId: string
}

const QuizModeSelector: React.FC<QuizModeSelectorProps> = ({ isOpen, onClose, examId }) => {
  const [selectedMode, setSelectedMode] = useState<string | null>('Review')
  const navigate = useNavigate() // Use useNavigate instead of useHistory

  const handleModeSelection = (mode: string) => {
    setSelectedMode((prevMode) => (prevMode === mode ? null : mode))
  }

  const startQuiz = async () => {
    navigate(`/quiz/review/${examId}`)
  }

  useEffect(() => {
    // Set the initial mode when the component mounts
    setSelectedMode('Review')
  }, []) // Empty dependency array ensures this runs only once on mount

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Exam Mode</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6} className={examId}>
            <HStack spacing={4} justifyContent={'space-evenly'}>
              <Flex alignItems='center'>
                <Button
                  variant={selectedMode === 'Review' ? 'solid' : 'outline'}
                  borderWidth={1}
                  borderColor={selectedMode === 'Review' ? 'brand.700' : 'none'}
                  onClick={() => selectedMode !== 'Review' && handleModeSelection('Review')}
                  _focus={{ border: '1px solid', borderColor: 'brand.500' }}
                  display={'flex'}
                  flexDirection={'column'}
                  height={'auto'}
                  padding={3}
                  minW={'130px'}
                >
                  <img
                    src={ReviewIcon}
                    alt='Review Mode'
                    style={{ width: '40px', height: '40px' }}
                  />
                  <Text mt={2}>Review</Text>
                </Button>
              </Flex>

              <Flex alignItems='center' direction={'row'} justifyContent={'center'}>
                <Tooltip label='Coming soon!' aria-label='A tooltip' placement='right'>
                  <Button
                    disabled
                    variant={'outline'}
                    display={'flex'}
                    cursor={'not-allowed'}
                    flexDirection={'column'}
                    height={'auto'}
                    padding={3}
                    minW={'130px'}
                  >
                    <img src={TestIcon} alt='Test Mode' style={{ width: '40px', height: '40px' }} />
                    <Text mt={2}>Test</Text>
                  </Button>
                </Tooltip>
              </Flex>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='brand' mr={3} onClick={startQuiz} isDisabled={!selectedMode}>
              Start
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default QuizModeSelector
