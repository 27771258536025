// src/components/home/HomePage.tsx
import React from 'react'
import { Box, Heading } from '@chakra-ui/react'
import QuizList from '../components/quiz/Quizlist'
import quizzes from '../data/exams.json'

const HomePage: React.FC = () => {
  return (
    <Box p='4'>
      <Heading
        as='h3'
        fontSize={'28px'}
        fontWeight={'600'}
        size='xl'
        mb={2}
        color='text.900'
        marginBottom='8'
      >
        All Quizzes
      </Heading>
      <QuizList />
    </Box>
  )
}

export default HomePage
