import React, { useState } from 'react'
import { Box, Text, VStack, Flex, Button, Collapse, Icon } from '@chakra-ui/react'
import { HiLockClosed, HiLockOpen } from 'react-icons/hi'
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md'
import QuizModeSelector from '../../components/quiz/QuizModeSelector'
import { Exam } from '../../interface/exam.interface'

interface ExamListProps {
  exams: Array<Exam>
}

const ExamList: React.FC<ExamListProps> = ({ exams }) => {
  const [openExams, setOpenExams] = useState<Record<string, boolean>>({})
  const [selectedExam, setSelectedExam] = useState<string | null>(null)

  const toggleExam = (examId: string) => {
    setOpenExams((prevOpenExams) => {
      const isOpen = !prevOpenExams[examId]
      return { ...prevOpenExams, [examId]: isOpen }
    })
  }

  const openQuizModeSelector = (event: React.MouseEvent<HTMLButtonElement>, examId: string) => {
    event.stopPropagation()
    setSelectedExam(examId)
  }

  const closeQuizModeSelector = () => {
    setSelectedExam(null)
  }

  return (
    <Flex width={'100%'}>
      <VStack spacing='5' wrap='wrap' align='top' width={'100%'}>
        {exams.map((exam) => (
          <Box key={exam._id} className={exam._id}>
            <Box borderWidth='1px' borderRadius='md' padding={2}>
              <Box p='2' onClick={() => toggleExam(exam._id)} cursor='pointer'>
                <Flex alignItems='center' justifyContent={'space-between'}>
                  <Flex alignItems={'center'}>
                    <Icon
                      as={openExams[exam._id] ? MdKeyboardArrowDown : MdKeyboardArrowRight}
                      fontSize='20px'
                      mr='2'
                    />
                    <Text fontWeight='semibold' fontSize='xl'>
                      {exam.name}
                    </Text>
                  </Flex>
                  <Flex justifyContent='flex-end'>
                    <Button
                      isDisabled={exam.active ? false : true}
                      colorScheme='brand'
                      size='sm'
                      onClick={(e) => openQuizModeSelector(e, exam._id)}
                    >
                      {!exam.active ? <HiLockClosed /> : <HiLockOpen />}
                      Take Exam
                    </Button>
                  </Flex>
                </Flex>
                <Collapse in={openExams[exam._id]}>
                  <Box mt={2} border='1px solid' borderColor='gray.200' p={3} borderRadius='md'>
                    <Text color='gray.600'>{exam.descriptions}</Text>
                  </Box>
                </Collapse>
              </Box>
            </Box>
            {selectedExam === exam._id && (
              <QuizModeSelector
                isOpen={true} // Always open for the selected exam
                onClose={closeQuizModeSelector}
                examId={exam._id}
              />
            )}
          </Box>
        ))}
      </VStack>
    </Flex>
  )
}

export default ExamList
