import React, { useState, useEffect } from 'react'
import {
  Box,
  Heading,
  Text,
  VStack,
  Collapse,
  Button,
  Flex,
  Switch,
  Skeleton,
} from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { HiEye, HiEyeOff } from 'react-icons/hi'
import NotFound from './NotFound'
import questionService from '../services/questionService'
import { Question } from '../interface/question.interface'
import { getTestExamById } from '../services/examService'
import { Exam } from '../interface/exam.interface'

const ReviewExam: React.FC = () => {
  const [expandedQuestions, setExpandedQuestions] = useState<string[]>([])
  const [questions, setQuestions] = useState<Question[]>([])
  const [testExam, setTestExam] = useState<Exam>()
  const [loading, setLoading] = useState(true)
  const [showAllAnswers, setShowAllAnswers] = useState(false)
  const [questionsWithAnswers, setQuestionsWithAnswers] = useState<{ [key: string]: boolean }>({})

  const { examId } = useParams<{ examId: string }>()

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const fetchedQuestions = examId && (await questionService.getQuestionsById(examId))
        fetchedQuestions && setQuestions(fetchedQuestions)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching questions:', error)
        setLoading(false)
      }
    }

    const fetchTestExam = async () => {
      try {
        const fetchedTestExam = examId && (await getTestExamById(examId))
        setTestExam(fetchedTestExam)
      } catch (error) {
        console.error('Error fetching test exam:', error)
      }
    }
    fetchTestExam()
    fetchQuestions()
  }, [examId])

  const toggleQuestion = (questionId: string) => {
    setExpandedQuestions((prevExpanded: string[]) =>
      prevExpanded.includes(questionId)
        ? prevExpanded.filter((id) => id !== questionId)
        : [...prevExpanded, questionId],
    )

    // Update the state for the specific question
    setQuestionsWithAnswers((prevQuestions) => ({
      ...prevQuestions,
      [questionId]: !prevQuestions[questionId],
    }))
  }

  const handleToggleAllAnswers = () => {
    setShowAllAnswers((prevShowAllAnswers) => !prevShowAllAnswers)
  }

  if (loading) {
    return (
      <VStack align='start' spacing={4}>
        <Skeleton mt={'20px'} height='30px' width='60%' />
        <Skeleton height='20px' width='30%' />
        <Box borderWidth='1px' borderRadius='md' p={4} width='100%'>
          <Skeleton mt={4} height={'20px'} width={'100%'} />
          <Skeleton mt={4} height={'20px'} width={'80%'} />
          <Skeleton mt={4} height={'20px'} width={'70%'} />
          <Skeleton mt={4} height={'20px'} width={'60%'} />
          <Skeleton mt={4} height={'40px'} width={'80px'} />
        </Box>
        <Box borderWidth='1px' borderRadius='md' p={4} width='100%'>
          <Skeleton mt={4} height={'20px'} width={'100%'} />
          <Skeleton mt={4} height={'20px'} width={'80%'} />
          <Skeleton mt={4} height={'20px'} width={'70%'} />
          <Skeleton mt={4} height={'20px'} width={'60%'} />
          <Skeleton mt={4} height={'20px'} width={'50%'} />
          <Skeleton mt={4} height={'40px'} width={'80px'} />
        </Box>
      </VStack>
    )
  }

  if (!questions || questions.length === 0) {
    return <NotFound />
  }

  return (
    <VStack align='start' spacing={4}>
      <Flex align={'start'} flexDirection={'column'}>
        <Heading
          mt={'20px'}
          as='h3'
          fontSize={'28px'}
          fontWeight={'600'}
          size='xl'
          mb='0'
          color='text.900'
        >
          Review Exam: {testExam?.name}
        </Heading>
        <Text>Number of question: {questions.length}</Text>
        <Switch mt={5} onChange={handleToggleAllAnswers} colorScheme='brand' size='md'>
          {showAllAnswers ? 'Hide All Answers' : 'Show All Answers'}
        </Switch>
      </Flex>
      {questions.map((question, index) => (
        <Box key={question._id} borderWidth='1px' borderRadius='md' p={4} width='100%'>
          <Heading as='h3' size='md' mb={2}>
            Question {`${index + 1}: ${question.description}`}
          </Heading>

          <VStack align='start' spacing={2}>
            {question.answers.map((option: any, optionIndex) => (
              <Box key={optionIndex}>
                <Text
                  fontWeight={
                    (questionsWithAnswers[question._id] || showAllAnswers) && option.correct
                      ? 'bold'
                      : 'normal'
                  }
                >
                  {String.fromCharCode(65 + optionIndex)} - {option.answer}
                </Text>
              </Box>
            ))}
          </VStack>
          <Button mt={4} onClick={() => toggleQuestion(question._id)}>
            {expandedQuestions.includes(question._id) || showAllAnswers ? (
              <Flex>
                <HiEyeOff />
                <Text ml={2}> Hide Answer</Text>
              </Flex>
            ) : (
              <Flex alignItems={'center'}>
                <HiEye />
                <Text ml={2}> Show Answer</Text>
              </Flex>
            )}
          </Button>
          <Collapse in={expandedQuestions.includes(question._id) || showAllAnswers}>
            <Box
              mt={4}
              mb={2}
              borderLeft={'2px'}
              paddingLeft={'12px'}
              paddingBottom={2}
              paddingTop={2}
              borderLeftColor={'brand.500'}
              bg={'brand.100'}
              borderRadius={2}
            >
              <Text>
                <Text fontWeight={'bold'}>
                  Correct Answer:{' '}
                  {String.fromCharCode(65 + question.answers.findIndex((ans) => ans.correct))}
                </Text>
                {question.answers.find((ans) => ans.correct)?.answer}
              </Text>
              <Text>
                {question.explanation && (
                  <Text fontWeight={'bold'}>Explanation: {question.explanation}</Text>
                )}
              </Text>
            </Box>
          </Collapse>
        </Box>
      ))}
    </VStack>
  )
}

export default ReviewExam
