import React, { useState, useEffect } from 'react'
import {
  Box,
  Image,
  Text,
  Badge,
  HStack,
  Flex,
  Skeleton,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { Subject } from '../../interface/subject.interface'
import { HiLockClosed } from 'react-icons/hi'
import subjectService from '../../services/subjectService'

const QuizList: React.FC = () => {
  const [subjects, setSubjects] = useState<Subject[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await subjectService.getSubjects()
        setSubjects(data)
        setLoading(false) // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error in component:', error)
      }
    }

    fetchData()
  }, [])

  return (
    <Flex>
      <HStack spacing='10' wrap={'wrap'} align={'top'}>
        {loading && (
          <Alert status='warning' borderRadius={'3px'}>
            <AlertIcon />
            <AlertTitle>Beta Version</AlertTitle>
            <AlertDescription>
              This is the beta version, so please be patient while the website connects to the
              server and loads data for the first time. We apologize for any inconvenience.
            </AlertDescription>
          </Alert>
        )}
        {loading
          ? Array.from({ length: 4 }).map((_, index) => (
              <Box key={index} borderWidth='1px' borderRadius='lg' overflow='hidden' maxW={'100%'}>
                <Box width={'300px'} minH={'390px'}>
                  <Skeleton height='200px' width={'100%'} mb='2' />
                  <Box p={6} paddingTop={0}>
                    <Skeleton height='20px' width={'100%'} mt='6' mb='3' />
                    <Skeleton height='15px' width={'100%'} mb='3' />
                    <Skeleton height='15px' width={'80%'} mb='3' />
                  </Box>
                </Box>
              </Box>
            ))
          : // Render the actual data once it is loaded
            subjects.map((quiz) => (
              <Link key={quiz._id} to={quiz.active ? `/quiz/${quiz._id}` : '#'}>
                <Box
                  borderWidth='1px'
                  borderRadius='lg'
                  overflow='hidden'
                  minH={'390px'}
                  maxW={'300px'}
                  _hover={{
                    bg: quiz.active ? 'brand.100' : '',
                    cursor: quiz.active ? 'pointer' : 'not-allowed',
                  }}
                >
                  <Image
                    width={'100%'}
                    height={'200px'}
                    objectFit={'cover'}
                    src={quiz.thumbnail}
                    alt={quiz.subject}
                  />
                  <Box p='6'>
                    <Text noOfLines={[1, 2]} fontWeight='semibold' fontSize='xl' mb='2'>
                      {quiz.subject}
                    </Text>
                    <Text noOfLines={[1, 2]} color='gray.600'>
                      {quiz.description}
                    </Text>
                    <Badge colorScheme={quiz.active ? 'green' : 'red'} mt='2'>
                      {quiz.active ? (
                        ''
                      ) : (
                        <Text display={'flex'} alignItems={'center'} gap={1}>
                          <HiLockClosed />
                          LOCKED
                        </Text>
                      )}
                    </Badge>
                  </Box>
                </Box>
              </Link>
            ))}
      </HStack>
    </Flex>
  )
}

export default QuizList
